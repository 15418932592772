import TagManager from 'react-gtm-module';
import { useStepsContext } from '../../Hooks/useStepsContext';
import { isAgeInRange } from '../../Utils/functions';
import AlertComponent from '../Alert/AlertComponent';
import Input from '../Input/Input';
import Button from '../Button/Button';
import BotResponse from '../BotResponse/BotResponse';

export default function RangeLookingForAge() {
  const {
    state,
    setState,
    next,
    back,
    setShouldSave,
    error,
    setError,
    fakeLoading,
    makeFakeLoading,
    fakeLoadingDuration,
    stepPassed,
  } = useStepsContext();
  const { lookingFor, fromAge, toAge, name } = state;

  const isFromAgeValid = fromAge && isAgeInRange(fromAge);
  const isToAgeValid = toAge && isAgeInRange(toAge);
  const isValidAgeRange = isFromAgeValid && isToAgeValid && fromAge <= toAge;

  const handleFromAgeChange = (fromAge) => {
    setShouldSave(true);
    setState((state) => ({ ...state, fromAge }));
  };

  const handleToAgeChange = (toAge) => {
    setShouldSave(true);
    setState((state) => ({ ...state, toAge }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    makeFakeLoading();

    setTimeout(() => {
      if (!isFromAgeValid && !isToAgeValid) return setError('bothInputsEmpty');
      if (!isFromAgeValid) return setError('fromAge');
      if (!isToAgeValid) return setError('toAge');
      if (!isValidAgeRange) return setError('flippedRange');

      // trigger event only once - do not repeat it if user pressed 'Back'
      if (!stepPassed && !name.toLowerCase().includes('test')) {
        // Allybot 2.0 - Trigger Age Range Page Complete
        TagManager.dataLayer({
          dataLayer: {
            event: 'allybot2_age_range_page',
          },
        });
      }

      if (isValidAgeRange) next();
    }, fakeLoadingDuration);
  };

  const getInvalidAgeMessage = (age) => {
    if (age === '') return 'This field cannot be blank.';
    if (age < 18) return 'Value must be greater than or equal to 18.';
    if (age > 100) return 'Value must be less than or equal to 100.';
  };

  // "I'm looking to meet single men/women" <- take last word
  const genderWords = lookingFor.split(' ');
  const menOrWomen = genderWords[genderWords.length - 1];

  return (
    <form name="ageRange" onSubmit={handleNext}>
      {error && <AlertComponent text="There was a problem with your submission. Errors are marked below." />}

      <BotResponse className="mb-30px">
        What's the general age range of {menOrWomen} you'd be interested in meeting?
      </BotResponse>

      <div className="RangeInputBoxContainer">
        <Input
          type="number"
          value={fromAge}
          setValue={handleFromAgeChange}
          min={18}
          max={100}
          placeholder="From"
          isInvalid={error && error !== 'toAge'}
          autoFocus
        />
        <span style={fakeLoading ? { opacity: '50%' } : {}}>&nbsp;—&nbsp;</span>
        <Input
          type="number"
          value={toAge}
          setValue={handleToAgeChange}
          min={18}
          max={100}
          placeholder="To"
          isInvalid={error && error !== 'fromAge'}
        />
      </div>

      {error && error !== 'flippedRange' && (
        <div className="double-error">
          <p className="has-error">{getInvalidAgeMessage(fromAge || '')}</p>
          <p className="has-error">{getInvalidAgeMessage(toAge || '')}</p>
        </div>
      )}

      {error === 'flippedRange' && (
        <p className="has-error">Please make sure that age from is less or equal to age to.</p>
      )}

      <div className="frm_submit">
        <Button label="Back" onClick={back} />
        <Button label="Next" onClick={handleNext} />
      </div>
    </form>
  );
}
