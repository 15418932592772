import TagManager from 'react-gtm-module';
import { useStepsContext } from '../../Hooks/useStepsContext';
import { getIncomeOptions } from '../../Utils/functions';
import AlertComponent from '../Alert/AlertComponent';
import Button from '../Button/Button';
import BotResponse from '../BotResponse/BotResponse';
import RadioOption from '../RadioOption/RadioOption';
import classes from 'classnames';

export default function Income() {
  const {
    state,
    setState,
    next,
    back,
    setShouldSave,
    error,
    setError,
    animate,
    makeFakeLoading,
    fakeLoadingDuration,
    stepPassed,
  } = useStepsContext();
  const { income, address, name } = state;

  const options = getIncomeOptions(address);

  const handleIncomeChange = (e) => {
    setShouldSave(true);
    setState((state) => ({ ...state, income: e.target.value }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    makeFakeLoading();

    setTimeout(() => {
      if (!income) return setError('error');

      // trigger event only once - do not repeat it if user pressed 'Back'
      if (!stepPassed && !name.toLowerCase().includes('test')) {
        // Allybot 2.0 - Trigger Income Page Complete
        TagManager.dataLayer({
          dataLayer: {
            event: 'allybot2_income_page',
          },
        });
      }

      next();
    }, fakeLoadingDuration);
  };

  return (
    <form name="income" onSubmit={handleNext}>
      {error && <AlertComponent text="There was a problem with your submission. Errors are marked below." />}

      <BotResponse className={classes(animate(0), 'mb-14px')}>
        As you know, your income level is relevant for dating. Most of our clients prefer to date someone on a similar
        professional level or higher...
      </BotResponse>

      <div className={animate(1)}>
        <BotResponse className="mb-20px">
          Could you share your current income level with me? It's completely confidential.
        </BotResponse>

        <div className="btn-group-toggle radioOptionGrid" data-toggle="buttons">
          {options.map((option) => (
            <RadioOption
              id={option.key}
              key={option.key}
              name="income"
              value={option.key}
              label={option.label}
              checked={income === option.key}
              onChange={handleIncomeChange}
              hasError={!!error}
            />
          ))}
        </div>
      </div>

      {error && <p className="has-error">You must select one of the options above.</p>}

      <div className="frm_submit">
        <Button label="Back" onClick={back} />
        <Button label="Next" onClick={handleNext} />
      </div>
    </form>
  );
}
