import TagManager from 'react-gtm-module';
import { useStepsContext } from '../../Hooks/useStepsContext';
import AlertComponent from '../Alert/AlertComponent';
import Input from '../Input/Input';
import Button from '../Button/Button';
import BotResponse from '../BotResponse/BotResponse';
import classes from 'classnames';

export default function EnterAge() {
  const {
    state,
    setState,
    next,
    back,
    setShouldSave,
    error,
    setError,
    makeFakeLoading,
    fakeLoadingDuration,
    stepPassed,
  } = useStepsContext();

  const { name, age } = state;

  const handleAgeChange = (age) => {
    setShouldSave(true);
    setState((state) => ({ ...state, age }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    makeFakeLoading();

    setTimeout(() => {
      if (!age) return setError('empty');
      if (age < 18) return setError('lt18');
      if (age > 100) return setError('gt100');

      // trigger event only once - do not repeat it if user pressed 'Back'
      if (!stepPassed && !name.toLowerCase().includes('test')) {
        // Allybot 2.0 - Trigger Age Page Complete
        TagManager.dataLayer({
          dataLayer: {
            event: 'allybot2_age_page',
          },
        });
      }

      next();
    }, fakeLoadingDuration);
  };

  return (
    <form name="age" onSubmit={handleNext}>
      {error && <AlertComponent text="There was a problem with your submission. Errors are marked below." />}

      <div className="age-wrapper">
        <BotResponse className={classes(error && 'has-error')}>
          How old are you, <strong>{name}</strong>?
        </BotResponse>

        <Input type="number" value={age} setValue={handleAgeChange} min={18} max={100} isInvalid={!!error} autoFocus />

        {error === 'empty' && <p className="has-error">This field cannot be blank.</p>}
        {error === 'lt18' && <p className="has-error">Value must be greater than or equal to 18.</p>}
        {error === 'gt100' && <p className="has-error">Value must be less than or equal to 100.</p>}
      </div>

      <div className="frm_submit">
        <Button label="Back" onClick={back} />
        <Button label="Next" onClick={handleNext} />
      </div>
    </form>
  );
}
