import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import App from './App.jsx';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://236f008247f4c026e53c438b88dff23f@sentry.enamour.com/3',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', 'https://allybot-test.vidaselect.com/api/'],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 100%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactGA.initialize('G-EQ1NSFGFM6');

// Cypress assigns window.isCypressTest property
// do not initialize tag manager if Cypress tests run
if (!window.isCypressTest) {
  TagManager.initialize({
    gtmId: 'GTM-M4ZRLP9',
    dataLayer: {
      event: 'allybot2_loaded',
    },
  });
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
