import axios from 'axios';
const { MODE, VITE_APIURL, VITE_TESTAPI, VITE_THANK_YOU_PAGE } = import.meta.env;

export const apiUrl = `${MODE === 'development' ? VITE_TESTAPI : VITE_APIURL}`;

// Get complete chat data object from db by session token
export const getChatFromDB = async () => {
  const token = localStorage.getItem('token');

  if (!token) return null;

  try {
    const response = await axios.get(`${apiUrl}/allybot_sessions`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return { state: response.data.state, leadType: response.data.leadType };
  } catch (err) {
    console.error(err.message);
    localStorage.removeItem('token');
    return null;
  }
};

// Update or create chat data object in db by session token
export const saveChatToDB = async (state, ip_address, ga_id, fingerprint) => {
  const token = localStorage.getItem('token');

  try {
    if (!token) {
      const response = await axios.post(`${apiUrl}/allybot_sessions`, { state, ip_address, ga_id, fingerprint });
      localStorage.setItem('token', response.data.token);
      return { leadType: null, adjustIncome: response.data?.adjustIncome };
    }

    const response = await axios.patch(
      `${apiUrl}/allybot_sessions`,
      { state },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    return { leadType: response.data?.leadType, adjustIncome: null };
  } catch (e) {
    console.error('Could not save current session.');
  }
};

// Make request to Zap webhook to create OP record
export const saveCompletedForm = async () => {
  const token = localStorage.getItem('token');

  try {
    await axios.post(`${apiUrl}/completed`, {}, { headers: { Authorization: `Bearer ${token}` } });
  } catch (err) {
    if (err.response.status === 403) {
      removeSessionToken();
      window.location.href = VITE_THANK_YOU_PAGE;
    }
  }
};

// Send event to Red Track about successful Lead creation
export const sendLeadToRedTrack = async (clickId) => {
  // if no click id - do nothing
  if (!clickId) return;

  try {
    await axios.get(`https://c.revmizer.com/postback?clickid=${clickId}&type=Lead`);
  } catch (err) {
    // TODO: should be ever handled?
    console.error(err.message);
  }
};

// Send event to Red Track about successful Appointment (calendly booking)
export const sendAppointmentToRedTrack = async (clickId) => {
  // if no click id - do nothing
  if (!clickId) return;

  try {
    await axios.get(`https://c.revmizer.com/postback?clickid=${clickId}&type=RT_Appointment`);
  } catch (err) {
    // TODO: should be ever handled?
    console.error(err.message);
  }
};

export const removeSessionToken = () => {
  localStorage.removeItem('token');
};
