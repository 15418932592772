import { useStepsContext } from '../../Hooks/useStepsContext';
import { determineCurrency } from '../../Utils/functions';
import AlertComponent from '../Alert/AlertComponent';
import BotResponse from '../BotResponse/BotResponse';
import Button from '../Button/Button';
import RadioOption from '../RadioOption/RadioOption';
import classes from 'classnames';

export default function LowIncomeDisclaimerAgreement() {
  const { state, setState, next, back, setShouldSave, error, setError, animate, makeFakeLoading, fakeLoadingDuration } =
    useStepsContext();

  const handleDisclaimerAgreementChange = (e) => {
    setShouldSave(true);
    setState((state) => ({ ...state, lowIncomeDisclaimerAgreement: e.target.value }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    makeFakeLoading();

    setTimeout(() => {
      if (!state.lowIncomeDisclaimerAgreement) return setError('error');

      next();
    }, fakeLoadingDuration);
  };

  // add converted currency for Canada, Australia and UK
  const currency = determineCurrency(state.address);
  const getCurrencyString = () => {
    switch (currency) {
      case 'cad':
        return ' (~$1,610 CAD)';
      case 'aud':
        return ' (~$1,845 AUD)';
      case 'gbp':
        return ' (~£940)';
      default:
        return '';
    }
  };

  return (
    <form name="disclaimer" onSubmit={handleNext}>
      {error && <AlertComponent text="There was a problem with your submission. Errors are marked below." />}

      <BotResponse className={classes(animate(0), 'mb-20px')}>
        VIDA Select packages start at <strong>$1,195{getCurrencyString()}</strong> per month. That's where we dedicate
        80 minutes to your search per day.
      </BotResponse>

      <BotResponse className={classes(animate(1), 'mb-20px')}>
        There's no long-term commitment... It's just one month at a time and clients typically meet someone special
        within 3 months.
      </BotResponse>

      <div className={animate(2)}>
        <BotResponse className="mb-20px">
          If you knew we could find your ideal match for you, would this be an investment you'd be willing to make?
        </BotResponse>

        <div className="btn-group-toggle" data-toggle="buttons">
          <RadioOption
            id="agree"
            name="disclaimer"
            value="yes"
            label="Yes"
            checked={state.lowIncomeDisclaimerAgreement === 'yes'}
            onChange={handleDisclaimerAgreementChange}
            hasError={!!error}
          />
          <RadioOption
            id="disagree"
            name="disclaimer"
            value="no"
            label="No"
            checked={state.lowIncomeDisclaimerAgreement === 'no'}
            onChange={handleDisclaimerAgreementChange}
            hasError={!!error}
          />
        </div>
      </div>

      {error && <p className="has-error">You must select one of the options above.</p>}

      <div className="frm_submit">
        <Button label="Back" onClick={back} />
        <Button label="Next" onClick={handleNext} />
      </div>
    </form>
  );
}
