const LOOKING_FOR_AND_GENDER = 0;
const ENTER_FIRST_NAME = 1;
const RELATIONSHIP_STATUS = 2;
const ENTER_CITY = 3;
const ENTER_AGE = 4;
const AGE_RANGE_OF_LOOKING_FOR = 5;
const WOMAN_MATCH_EARN = 6;
const CURRENT_INCOME_LEVEL = 7;
const DATING_OBJECTIVE = 8;
const LOW_INCOME_AGREEMENT = 9;
const NEWSLETTER_SIGNUP = 10;
const AGREED_TO_BUNDLE_GET_EMAIL = 11;
const READY_TO_SPEAK_GET_EMAIL = 12;
const PHONE_OR_VIDEO = 13;
const CALENDLY_PAGE = 14;

const steps = {
  LOOKING_FOR_AND_GENDER,
  ENTER_FIRST_NAME,
  RELATIONSHIP_STATUS,
  ENTER_CITY,
  ENTER_AGE,
  AGE_RANGE_OF_LOOKING_FOR,
  WOMAN_MATCH_EARN,
  CURRENT_INCOME_LEVEL,
  DATING_OBJECTIVE,
  LOW_INCOME_AGREEMENT,
  NEWSLETTER_SIGNUP,
  AGREED_TO_BUNDLE_GET_EMAIL,
  READY_TO_SPEAK_GET_EMAIL,
  PHONE_OR_VIDEO,
  CALENDLY_PAGE,
};

export default steps;

export const primeCities = [
  'Alexandria',
  'Arlington',
  'Atlanta',
  'Aurora',
  'Austin',
  'Baltimore',
  'Bellevue',
  'Bloomington',
  'Boston',
  'Cambridge',
  'Camden',
  'Carlsbad',
  'Cary',
  'Charlotte',
  'Chicago',
  'Columbia',
  'Concord',
  'Dallas',
  'Dearborn',
  'Denver',
  'Detroit',
  'Elgin',
  'Fort Lauderdale',
  'Fort Worth',
  'Gastonia',
  'Hayward',
  'Houston',
  'Jersey City',
  'Lakewood',
  'London',
  'Long Beach',
  'Los Angeles',
  'Mesa',
  'Miami',
  'Minneapolis',
  'Naperville',
  'New York',
  'Newark',
  'Newton',
  'Oakland',
  'Philadelphia',
  'Phoenix',
  'Raleigh',
  'Roswell',
  'Round Rock',
  'San Diego',
  'San Francisco',
  'San Jose',
  'Sandy Springs',
  'Santa Clara',
  'Scottsdale',
  'Seattle',
  'St. Paul',
  'Sugar Land',
  'Sunnyvale',
  'Sydney',
  'Tacoma',
  'The Woodlands',
  'Toronto',
  'Towson',
  'Vancouver',
  'Warren',
  'Washington',
  'West Palm Beach',
  'Wilmington',
];
