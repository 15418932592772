import TagManager from 'react-gtm-module';
import { useStepsContext } from '../../Hooks/useStepsContext';
import AlertComponent from '../Alert/AlertComponent';
import Button from '../Button/Button';
import BotResponse from '../BotResponse/BotResponse';
import RadioOption from '../RadioOption/RadioOption';
import classes from 'classnames';

export default function RelationStatus() {
  const {
    state,
    setState,
    next,
    back,
    setShouldSave,
    error,
    setError,
    animate,
    makeFakeLoading,
    fakeLoadingDuration,
    stepPassed,
  } = useStepsContext();

  const { relationStatus, name, gender } = state;

  const options = ['Single', 'Married', 'Divorced', 'Separated', 'Widowed', 'It`s Complicated'];

  const handleRelationChange = (e) => {
    setShouldSave(true);
    setState((state) => ({ ...state, relationStatus: e.target.value }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    makeFakeLoading();

    setTimeout(() => {
      if (!relationStatus) return setError('error');

      // trigger event only once - do not repeat it if user pressed 'Back'
      if (!stepPassed && !name.toLowerCase().includes('test')) {
        // Allybot 2.0 - Trigger Relationship Status Page Complete
        TagManager.dataLayer({
          dataLayer: {
            event: 'allybot2_relation_status_page',
          },
        });
      }

      next();
    }, fakeLoadingDuration);
  };

  return (
    <form name="relationshipStatus" onSubmit={handleNext}>
      {error && <AlertComponent text="There was a problem with your submission. Errors are marked below." />}

      <BotResponse className={classes(animate(0), 'mb-14px')}>
        Nice to meet you, <strong>{name}</strong>. {gender === 'I am a man' ? "So what's" : "What's"} your current{' '}
        <strong>relationship status</strong>?
      </BotResponse>

      <div className={`btn-group-toggle ${animate(1)}`} data-toggle="buttons">
        {options.map((option) => (
          <RadioOption
            id={option}
            key={option}
            name="relationStatus"
            value={option}
            label={option}
            checked={relationStatus === option}
            onChange={handleRelationChange}
            hasError={!!error}
          />
        ))}

        {error && <p className="has-error">You must select one of the options above.</p>}
      </div>

      {relationStatus === 'Married' && (
        <BotResponse>
          We don't help people cheat on their partners, but we can discuss your unique situation when we talk!
        </BotResponse>
      )}

      {relationStatus === 'It`s Complicated' && (
        <BotResponse>Relationships can be complicated. We can chat more about this when we speak!</BotResponse>
      )}

      <div className="frm_submit">
        <Button label="Back" onClick={back} />
        <Button label="Next" onClick={handleNext} />
      </div>
    </form>
  );
}
