import { useStepsContext } from '../../Hooks/useStepsContext';
import { isEmailValid } from '../../Utils/functions';
import AlertComponent from '../Alert/AlertComponent';
import Input from '../Input/Input';
import Button from '../Button/Button';
import BotResponse from '../BotResponse/BotResponse';
import classes from 'classnames';

export default function SubscribeToNews() {
  const { state, setState, next, back, setShouldSave, error, setError, animate, makeFakeLoading, fakeLoadingDuration } =
    useStepsContext();

  const handleEmailChange = (email) => {
    setShouldSave(true);
    setState((state) => ({ ...state, email }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    makeFakeLoading();

    setTimeout(() => {
      if (!state.email) return setError('empty');
      if (!isEmailValid(state.email)) return setError('invalid');

      next();
    }, fakeLoadingDuration);
  };

  return (
    <form name="email" onSubmit={handleNext}>
      {error && <AlertComponent text="There was a problem with your submission. Errors are marked below." />}

      <BotResponse className={classes(animate(0), error && 'has-error', 'mb-30px')}>
        Subscribe to our free newsletter now by entering your email address below.
      </BotResponse>

      <Input
        type="email"
        value={state.email}
        setValue={handleEmailChange}
        placeholder="Enter your BEST email address!"
        isInvalid={!!error}
        className="fade-in"
      />

      {error === 'empty' && <p className="has-error">This field cannot be blank.</p>}
      {error === 'invalid' && <p className="has-error">Email is invalid.</p>}

      <div className="frm_submit">
        <Button label="Back" onClick={back} />
        <Button label="Next" onClick={handleNext} />
      </div>
    </form>
  );
}
