import { Form, InputGroup } from 'react-bootstrap';
import { useStepsContext } from '../../Hooks/useStepsContext';
import { capitalizeStr, preventNonNumericalInput } from '../../Utils/functions';
import classes from 'classnames';
import './style.css';

export default function Input({ type, placeholder, value, setValue, min, max, isInvalid, autoFocus, className }) {
  const { fakeLoading } = useStepsContext();

  const handleInputChange = (e) => {
    switch (type) {
      case 'text':
        if (e.target.value.trim()) {
          const value = capitalizeStr(e.target.value);
          setValue(value);
        } else {
          setValue('');
        }
        break;

      case 'number':
        setValue(Number(e.target.value));
        break;

      case 'email':
        setValue(e.target.value);
        break;

      default:
        throw new Error('Unsupported input type');
    }
  };

  return (
    <InputGroup className={classes(className)}>
      <Form.Control
        isInvalid={isInvalid}
        className={classes('inputField', fakeLoading && 'transparent')}
        type={type}
        placeholder={placeholder}
        min={min}
        max={max}
        value={value || ''}
        autoFocus={autoFocus}
        onChange={handleInputChange}
        onKeyDown={type === 'number' ? (e) => preventNonNumericalInput(e) : undefined}
      />
    </InputGroup>
  );
}
