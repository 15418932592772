import { removeSessionToken } from './api';
import { primeCities } from './constants';

// uppercase the first letter of the input string
export function capitalizeStr(str) {
  return str[0].toUpperCase() + str.slice(1);
}

export function determineCurrency(address) {
  const countryCurrency = {
    AU: 'aud',
    Australia: 'aud',
    CA: 'cad',
    Canada: 'cad',
    UK: 'gbp',
    GB: 'gbp',
    England: 'gpb',
  };

  // replace all non-alphabetical characters and trailing space
  const noDigitsAndCommas = address.replace(/[^a-zA-Z\s]/g, '').replace(/\s+$/, '');

  for (const country in countryCurrency) {
    // look for a match with the last word (CA, AU, GB, UK)
    if (noDigitsAndCommas.match(/\b(\w+)$/)[0] === country) {
      return countryCurrency[country];
    }
  }

  return 'usd';
}

// check if city is in the primary list
export function isPrimeCity(address) {
  for (const city of primeCities) {
    const regex = new RegExp('\\b' + city + '\\b', 'i');
    if (regex.test(address)) return true;
  }

  return false;
}

// prevent non-numeric input in Safari and Firefox
export function preventNonNumericalInput(e) {
  if (
    e.target.type === 'number' &&
    e.key !== 'Backspace' &&
    e.key !== 'Enter' &&
    e.key !== 'Tab' &&
    e.key !== 'F5' &&
    !e.key.match(/^[0-9]+$/)
  ) {
    e.preventDefault();
  }
}

export function getIncomeOptions(address) {
  const currency = determineCurrency(address);

  const options = {
    usd: [
      { key: '0-45', label: 'Under $45k' },
      { key: '45-60', label: '$45k - $60k' },
      { key: '60-75', label: '$60k - $75k' },
      { key: '75-100', label: '$75k - $100k' },
      { key: '100-150', label: '$100k - $150k' },
      { key: '150-250', label: '$150k - $250k' },
      { key: '250-500', label: '$250k - $500k' },
      { key: '500+', label: 'Over $500k' },
    ],
    aud: [
      { key: '0-45', label: 'Under $65k AUD' },
      { key: '45-60', label: '$65k - $85k AUD' },
      { key: '60-75', label: '$85k - $100k AUD' },
      { key: '75-100', label: '$100k - $150k AUD' },
      { key: '100-150', label: '$150k - $250k AUD' },
      { key: '150-250', label: '$250k - $350k AUD' },
      { key: '250-500', label: '$350k - $700k AUD' },
      { key: '500+', label: 'Over $700k AUD' },
    ],
    cad: [
      { key: '0-45', label: 'Under $60k CAD' },
      { key: '45-60', label: '$60k - $80k CAD' },
      { key: '60-75', label: '$80k - $100k CAD' },
      { key: '75-100', label: '$100k - $130k CAD' },
      { key: '100-150', label: '$130k - $200k CAD' },
      { key: '150-250', label: '$200k - $335k CAD' },
      { key: '250-500', label: '$335k - $500k CAD' },
      { key: '500+', label: 'Over $500k CAD' },
    ],
    gbp: [
      { key: '0-45', label: 'Under £35k GBP' },
      { key: '45-60', label: '£35k - £45k GBP' },
      { key: '60-75', label: '£45k - £60k GBP' },
      { key: '75-100', label: '£60k - £75k GBP' },
      { key: '100-150', label: '£75k - £120k GBP' },
      { key: '150-250', label: '£120k - £200k GBP' },
      { key: '250-500', label: '£200k - £450k GBP' },
      { key: '500+', label: 'Over £450k GBP' },
    ],
  };

  return options[currency];
}

export function getCalendlyLinkByIncome(income, adjustIncome) {
  const calendlyLinksArray = [
    {
      income: '0-45',
      man_phone: '45m-phone',
      man_video: 'vidaconsultation45',
      woman_phone: '45w-phone',
      woman_video: 'vida-consultation45w',
    },
    {
      income: '45-60',
      man_phone: '45m-phone',
      man_video: 'vidaconsultation45',
      woman_phone: '45w-phone',
      woman_video: 'vida-consultation45w',
    },
    {
      income: '60-75',
      man_phone: '45m-phone',
      man_video: 'vidaconsultation45',
      woman_phone: '45w-phone',
      woman_video: 'vida-consultation45w',
    },
    {
      income: '75-100',
      man_phone: '75m-phone',
      man_video: 'vidaconsultation75',
      woman_phone: '75w-phone',
      woman_video: 'vida-consultation75w',
    },
    {
      income: '100-150',
      man_phone: '100m-phone',
      man_video: 'vidaconsultation100',
      woman_phone: '100w-phone',
      woman_video: 'vida-consultation100w',
    },
    {
      income: '150-250',
      man_phone: '150m-phone',
      man_video: 'vidaconsultation150',
      woman_phone: '150w-phone',
      woman_video: 'vidaconsultation150w',
    },
    {
      income: '250-500',
      man_phone: '250m-phone',
      man_video: 'vidaconsultation250',
      woman_phone: '250w-phone',
      woman_video: 'vidaconsultation250w',
    },
    {
      income: '500+',
      man_phone: '500m-phone',
      man_video: 'vida-consultation-500m',
      woman_phone: '500w-phone',
      woman_video: 'vida-consultation-500w',
    },
  ];

  const indexByIncome = calendlyLinksArray.findIndex((linksObj) => linksObj.income === income);

  // downgrade income by one tier if 'adjustIncome' is true (do not perform for lowest and highest income '0-45', '500+')
  const shouldAdjust = adjustIncome && income !== '0-45' && income !== '500+';
  return calendlyLinksArray[shouldAdjust ? indexByIncome - 1 : indexByIncome];
}

// check whether age is >= 18 and <= 100
export function isAgeInRange(age) {
  return age >= 18 && age <= 100;
}

export function isEmailValid(email) {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

// compose address string from Google Autosuggest place.address_components.short_name for i18n
export function composeAddress(components) {
  if (!components) return null;

  let address = '';

  for (let i = 0; i < components.length; ++i) {
    const curLocName = components[i].short_name;
    const prevLocName = components[i > 0 ? i - 1 : i].short_name;

    if (i === 0) {
      address += curLocName;
      continue;
    }

    if (curLocName === prevLocName) continue;

    address += `, ${curLocName}`;
  }

  return address;
}

export function redirect(url) {
  removeSessionToken();
  // wait for some time to clear localStorage before redirect
  setTimeout(() => {
    window.location.href = url;
  }, 10);
}
