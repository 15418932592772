import { useEffect, useState } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga4';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const useUserTracking = () => {
  const [ipAddress, setIpAddress] = useState(null);
  const [GAId, setGAId] = useState(null);
  const [fingerprintId, setFingerprintId] = useState(null);

  // get IP address
  useEffect(() => {
    axios
      .get('https://api.ipify.org')
      .then(({ data }) => setIpAddress(data))
      .catch((error) => console.log(error));
  }, []);

  // get FingerprintJS visitorId
  useEffect(() => {
    const getFingerprintId = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      setFingerprintId(result.visitorId);
    };

    getFingerprintId();
  }, []);

  // Get rawGA cause we need prefix "GA1.1" ??
  useEffect(() => {
    ReactGA.ga(() => {
      const rawGA = document.cookie
        .split('; ')
        .find((c) => c.startsWith('_ga='))
        ?.split('=')[1];

      setGAId(rawGA);
    });
  }, []);

  return { ipAddress, GAId, fingerprintId };
};
