import { Image } from 'react-bootstrap';
import { useStepsContext } from '../../Hooks/useStepsContext';
import botAvatar from '../../assets/images/bot-avatar.png';
import classes from 'classnames';
import style from './botResponse.module.scss';

export default function BotResponse({ className, children }) {
  const { fakeLoading } = useStepsContext();

  return (
    <div className={classes(style.botResponse, className, fakeLoading && style.transparent)}>
      <Image src={botAvatar} alt="Ally" />

      <div className={style.message}>
        <p>{children}</p>
      </div>
    </div>
  );
}
