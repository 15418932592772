import { useStepsContext } from '../../Hooks/useStepsContext';
import classes from 'classnames';
import './style.scss';

export default function RadioOption({ id, name, label, checked, hasError, value, onChange, className }) {
  const { fakeLoading } = useStepsContext();

  return (
    <label
      className={classes(
        'btn',
        'btn-secondary',
        'customRadioStyle',
        checked && 'checked',
        hasError && 'has-error',
        fakeLoading && 'transparent',
        className
      )}
      htmlFor={id}
    >
      <input
        id={id}
        type="radio"
        role="radio"
        className="btn-check"
        name={name}
        defaultChecked={checked}
        value={value}
        onChange={onChange}
      />
      <span>&nbsp;&nbsp;</span>
      {label}
    </label>
  );
}
