import TagManager from 'react-gtm-module';
import { useStepsContext } from '../../Hooks/useStepsContext';
import AlertComponent from '../Alert/AlertComponent';
import Input from '../Input/Input';
import Button from '../Button/Button';
import BotResponse from '../BotResponse/BotResponse';
import classes from 'classnames';

export default function EnterName() {
  const {
    state,
    setState,
    next,
    back,
    setShouldSave,
    error,
    setError,
    animate,
    makeFakeLoading,
    fakeLoadingDuration,
    stepPassed,
  } = useStepsContext();

  const { name, gender, lookingFor } = state;

  let response;

  if (gender === 'I am a man' && lookingFor === 'I am looking to meet single women') {
    response = "Great, now I'd like to learn a bit about you & what you're looking for in your ideal woman.";
  } else if (gender === 'I am a woman' && lookingFor === 'I am looking to meet single men') {
    response = "Great, now I'd like to learn a bit about you & what you're looking for in your ideal partner.";
  } else if (
    (gender === 'I am a man' && lookingFor === 'I am looking to meet single men') ||
    (gender === 'I am a woman' && lookingFor === 'I am looking to meet single women')
  ) {
    response = 'Great, we have plenty of LGBTQ+ experience!';
  }

  const handleNameChange = (name) => {
    setShouldSave(true);
    setState((state) => ({ ...state, name }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    makeFakeLoading();

    setTimeout(() => {
      if (!name) return setError('error');

      // trigger event only once - do not repeat it if user pressed 'Back'
      if (!stepPassed && !name.toLowerCase().includes('test')) {
        // Allybot 2.0 - Trigger Name Page Complete
        TagManager.dataLayer({
          dataLayer: {
            event: 'allybot2_name_entered',
          },
        });
      }

      next();
    }, fakeLoadingDuration);
  };

  return (
    <form name="firstName" onSubmit={handleNext}>
      {error && <AlertComponent text="There was a problem with your submission. Errors are marked below." />}

      <BotResponse className="mb-20px">{response}</BotResponse>

      <BotResponse className={classes(animate(1), 'mb-14px')}>
        My questions are easy & will only take 2 mins!
      </BotResponse>

      <div className={animate(2)}>
        <BotResponse className={classes(error && 'has-error', 'mb-20px')}>
          To start, could you tell me your <strong>first name</strong>?
        </BotResponse>

        <Input
          value={name}
          setValue={handleNameChange}
          type="text"
          inputBoxType="single"
          placeholder="Enter your name here..."
          isInvalid={!!error}
          autoFocus
        />
      </div>

      {error && <p className="has-error">This field cannot be blank.</p>}

      <div className="frm_submit">
        <Button label="Back" onClick={back} />
        <Button label="Next" onClick={handleNext} />
      </div>
    </form>
  );
}
