import { useStepsContext } from '../../Hooks/useStepsContext';

import LookingFor from './1_LookingFor';
import EnterName from './2_EnterName';
import RelationStatus from './3_RelationStatus';
import EnterCity from './4_EnterCity';
import EnterAge from './5_EnterAge';
import RangeLookingForAge from './6_RangeLookingForAge';
import MatchEarn from './7_MatchEarn';
import Income from './8_Income';
import DatingObjective from './9_DatingObjective';
import LowIncomeDisclaimerAgreement from './10_LowIncomeDisclaimerAgreement';
import NewsletterSignup from './11_NewsletterSignup';
import SubscribeToNews from './12_SubscribeToNews';
import ReadyToSpeak from './15_ReadyToSpeak';
import PhoneOrVideo from './16_PhoneOrVideo';
import CalendlyBookingPage from './17_CalendlyBookingPage';

import steps from '../../Utils/constants';

// Every page displayed depending on state.step value
const pages = [
  <LookingFor key={steps.LOOKING_FOR_AND_GENDER} />,
  <EnterName key={steps.ENTER_FIRST_NAME} />,
  <RelationStatus key={steps.RELATIONSHIP_STATUS} />,
  <EnterCity key={steps.ENTER_CITY} />,
  <EnterAge key={steps.ENTER_AGE} />,
  <RangeLookingForAge key={steps.AGE_RANGE_OF_LOOKING_FOR} />,
  <MatchEarn key={steps.WOMAN_MATCH_EARN} />,
  <Income key={steps.CURRENT_INCOME_LEVEL} />,
  <DatingObjective key={steps.DATING_OBJECTIVE} />,
  <LowIncomeDisclaimerAgreement key={steps.LOW_INCOME_AGREEMENT} />,
  <NewsletterSignup key={steps.NEWSLETTER_SIGNUP} />,
  <SubscribeToNews key={steps.AGREED_TO_BUNDLE_GET_EMAIL} />,
  <ReadyToSpeak key={steps.READY_TO_SPEAK_GET_EMAIL} />,
  <PhoneOrVideo key={steps.PHONE_OR_VIDEO} />,
];

export default function Steps() {
  const { state, setState, phoneLink, videoLink, leadType } = useStepsContext();

  // set clickId and utm parameters if found
  if (!state.params) {
    const urlSearchParams = new URLSearchParams(location.search);
    setState((state) => ({
      ...state,
      params: {
        clickId: urlSearchParams.get('clickid'),
        utm_source: urlSearchParams.get('utm_source'),
        utm_medium: urlSearchParams.get('utm_medium'),
        utm_campaign: urlSearchParams.get('utm_campaign'),
        utm_term: urlSearchParams.get('utm_term'),
        utm_content: urlSearchParams.get('utm_content'),
      },
    }));
  }

  // TODO: workaround
  // Preload Calendly iframes when both phone and video links are available and the leadType is not 'Junk'.
  // The <CalendlyBookingPage/> is set to 'display: none' while the step is not CALENDLY_PAGE.
  // However, this step is rendered immediately after the income page for non-'Junk' leads.
  // This action provides sufficient time for the iframes to fully load, preventing clients from waiting unnecessarily.
  // If the user prefers a phone meeting, the phone link iframe is displayed; otherwise, the video link iframe is shown.
  if (phoneLink && videoLink && leadType && leadType !== 'Junk') {
    return (
      <>
        {pages[state.step]}
        <CalendlyBookingPage />
      </>
    );
  }

  return pages[state.step];
}
