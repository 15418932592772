import TagManager from 'react-gtm-module';
import { useStepsContext } from '../../Hooks/useStepsContext';
import AlertComponent from '../Alert/AlertComponent';
import Button from '../Button/Button';
import BotResponse from '../BotResponse/BotResponse';
import RadioOption from '../RadioOption/RadioOption';

export default function DatingObjective() {
  const {
    state,
    setState,
    next,
    back,
    setShouldSave,
    error,
    setError,
    makeFakeLoading,
    fakeLoadingDuration,
    stepPassed,
  } = useStepsContext();
  const { lookingFor, datingObjective, name } = state;

  const options =
    lookingFor === 'I am looking to meet single women'
      ? [
          'My friend recommended VIDA Select to me',
          'I find dating apps to be too time consuming and/or frustrating',
          "I want to meet higher quality matches than I've been getting on my own",
          "I'm curious to explore new options for meeting people",
          "I'm barely meeting anyone these days",
        ]
      : [
          'My friend recommended VIDA Select to me',
          'Dating apps are an overwhelming part-time job!',
          'I don’t seem to be attracting the right matches',
          'I’m searching for the best matchmaker',
          'Meeting someone special is my top priority',
        ];

  const handleDatingObjectiveChange = (e) => {
    setShouldSave(true);
    setState((state) => ({ ...state, datingObjective: e.target.value }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    makeFakeLoading();

    setTimeout(() => {
      if (!datingObjective) return setError('error');

      // trigger event only once - do not repeat it if user pressed 'Back'
      if (!stepPassed && !name.toLowerCase().includes('test')) {
        // Allybot 2.0 - Trigger Dating Objective Page Complete
        TagManager.dataLayer({
          dataLayer: {
            event: 'allybot2_dating_objective_page',
          },
        });
      }

      next();
    }, fakeLoadingDuration);
  };

  return (
    <form name="datingObjective" onSubmit={handleNext}>
      {error && <AlertComponent text="There was a problem with your submission. Errors are marked below." />}

      <BotResponse className="mb-20px">
        Which of the following best describes what inspired you to reach out?
      </BotResponse>

      <div className="btn-group-toggle widerOptions" data-toggle="buttons">
        {options.map((option) => (
          <RadioOption
            id={option}
            key={option}
            name="datingObjective"
            value={option}
            label={option}
            checked={datingObjective === option}
            onChange={handleDatingObjectiveChange}
            hasError={!!error}
          />
        ))}
      </div>

      {error && <p className="has-error">You must select one of the options above.</p>}

      <div className="frm_submit">
        <Button label="Back" onClick={back} />
        <Button label="Next" onClick={handleNext} />
      </div>
    </form>
  );
}
