import { useStepsContext } from '../../Hooks/useStepsContext';
import { determineCurrency } from '../../Utils/functions';
import AlertComponent from '../Alert/AlertComponent';
import Button from '../Button/Button';
import BotResponse from '../BotResponse/BotResponse';
import RadioOption from '../RadioOption/RadioOption';
import classes from 'classnames';

export default function MatchEarn() {
  const { state, setState, next, back, setShouldSave, error, setError, animate, makeFakeLoading, fakeLoadingDuration } =
    useStepsContext();
  const { address, matchEarn } = state;

  const currency = determineCurrency(address).toUpperCase();
  const curStr = currency !== 'USD' ? ` ${currency}` : '';
  const sign = currency === 'GBP' ? '£' : '$';

  const options = [
    "That doesn't matter to me",
    `Over ${sign}45k${curStr}`,
    `Over ${sign}60k${curStr}`,
    `Over ${sign}75k${curStr}`,
    `Over ${sign}100k${curStr}`,
    `Over ${sign}150k${curStr}`,
    `Over ${sign}250k${curStr}`,
    `Over ${sign}500k${curStr}`,
  ];

  const handleMatchEarnChange = (e) => {
    setShouldSave(true);
    setState((state) => ({ ...state, matchEarn: e.target.value }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    makeFakeLoading();

    setTimeout(() => {
      if (!matchEarn) return setError('error');

      next();
    }, fakeLoadingDuration);
  };

  return (
    <form name="matchEarn" onSubmit={handleNext}>
      {error && <AlertComponent text="There was a problem with your submission. Errors are marked below." />}

      <BotResponse className={classes(animate(0), 'mb-20px')}>
        As a matchmaker, I know that many singles prefer to date someone on a similar professional level... How much
        would your ideal match earn per year?
      </BotResponse>

      <div className={`btn-group-toggle radioOptionGrid widerOptions ${animate(1)}`} data-toggle="buttons">
        {options.map((option) => (
          <RadioOption
            id={option}
            key={option}
            name="matchEarn"
            value={option}
            label={option}
            checked={matchEarn === option}
            onChange={handleMatchEarnChange}
            hasError={!!error}
          />
        ))}
      </div>

      {error && <p className="has-error">You must select one of the options above.</p>}

      <div className="frm_submit">
        <Button label="Back" onClick={back} />
        <Button label="Next" onClick={handleNext} />
      </div>
    </form>
  );
}
