import { useStepsContext } from '../../Hooks/useStepsContext';
import steps from '../../Utils/constants';
import CalendlyPageFooter from './CalendlyPageFooter';
import classes from 'classnames';

import logo from '../../assets/images/vida-logo-red.png';
import media from '../../assets/images/header-media-logos.png';
import style from './layout.module.scss';
import '../Steps/style.scss';

export default function Layout({ children }) {
  const {
    state: { step },
    stateLoading,
  } = useStepsContext();

  const calendlyStep = step === steps.CALENDLY_PAGE;

  let content;

  if (!stateLoading) {
    content = (
      <main className={classes('main', calendlyStep && 'calendlyMain')}>
        <div className="chat noScrollBar">{children}</div>
      </main>
    );
  } else {
    content = <div className="chat noScrollBar"></div>;
  }

  return (
    <>
      <header className={style.header} id="top">
        <div className={style.wrapper}>
          <div className={style.logo}>
            <img src={logo} alt="Vida" />
          </div>
          <div className={style.branding}>
            <img src={media} alt="media" />
          </div>
        </div>
      </header>

      {content}

      <footer className={classes(style.footer, calendlyStep && style.calendlyFooter)}>
        <div className={style.feature}>
          <img
            alt="Over a decade of experience making great matches"
            src="https://www.vidaselect.com/wp-content/uploads/2021/05/est2009-150x150.png"
            srcSet=""
            sizes="(max-width: 150px) 100vw, 150px"
          />
          <p>Over a decade of experience making great matches</p>
        </div>

        <div className={style.feature}>
          <img
            alt="Over 5000 clients successfully matched"
            src="https://www.vidaselect.com/wp-content/uploads/2021/05/grouppeople-150x150.png"
            srcSet=""
            sizes="(max-width: 150px) 100vw, 150px"
          />
          <p>Over 5,000 clients successfully matched</p>
        </div>

        <div className={style.feature}>
          <img
            alt="92% of clients say they would recommend VIDA to a friend"
            src="https://www.vidaselect.com/wp-content/uploads/2021/05/megaphone150-150x150.png"
            srcSet=""
            sizes="(max-width: 150px) 100vw, 150px"
          />
          <p>92% of clients say they would recommend VIDA to a friend</p>
        </div>

        <div className={style.feature}>
          <img
            alt="78% clients find serious relationships within 3 months"
            src="https://www.vidaselect.com/wp-content/uploads/2021/05/couple150-150x150.png"
            srcSet=""
            sizes="(max-width: 150px) 100vw, 150px"
          />
          <p>78% of clients find serious relationships within 3½ months</p>
        </div>
      </footer>

      {calendlyStep && <CalendlyPageFooter />}
    </>
  );
}
