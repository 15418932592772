import { useEffect } from 'react';
import { useStepsContext } from '../../Hooks/useStepsContext';
import { Form } from 'react-bootstrap';
import { usePlacesWidget } from 'react-google-autocomplete';
import { composeAddress } from '../../Utils/functions';
import classes from 'classnames';
import './style.css';

export default function GoogleAutoSuggest({ address, setAddress, isInvalid, className }) {
  const { fakeLoading } = useStepsContext();

  const { ref } = usePlacesWidget({
    apiKey: import.meta.env.VITE_GOOGLEAPIKEY,
    language: 'en',
    onPlaceSelected: (place) => {
      const address = composeAddress(place?.address_components);
      setAddress(address);
    },
  });

  // show alert if address is not selected and clicked away (mimic v1.0)
  useEffect(() => {
    const listener = () => {
      if (document.activeElement === ref.current) ref.current.isDirty = true;

      if (document.activeElement !== ref.current && ref.current.isDirty && !address) {
        alert('We could not found the location you entered.');
        ref.current.isDirty = false;
      }
    };

    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [ref, address]);

  return (
    <div className={classes(className)}>
      <Form.Control
        className={classes('inputField', fakeLoading && 'transparent')}
        placeholder="Enter city here..."
        isInvalid={isInvalid}
        defaultValue={address || ''}
        ref={ref}
      />
    </div>
  );
}
