import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import Spinner from 'react-bootstrap/Spinner';
import { useStepsContext } from '../../Hooks/useStepsContext';
import { useMediaQuery } from '../../Hooks/useMediaQuery';
import { removeSessionToken, sendAppointmentToRedTrack } from '../../Utils/api';
import steps from '../../Utils/constants';

/* After booking redirects to Allybot v1.0 'confirmation' page */
export default function CalendlyBookingPage() {
  const { state, phoneLink, videoLink } = useStepsContext();

  const mw423px = useMediaQuery('(min-width: 423px)');
  const mw650px = useMediaQuery('(min-width: 650px)');

  let height = 'calc(100% - 8.5rem)';
  if (mw423px) height = 'calc(100% - 8.5rem)';
  if (mw650px) height = 'calc(100% - 5.4rem)';

  const widgetStyles = {
    position: 'absolute',
    left: '3rem',
    right: '3rem',
    height,
    maxWidth: '64rem',
    margin: '0 auto',
  };

  const notVisibleStyles = {
    display: 'none',
    visibility: 'hidden',
    width: 0,
    height: 0,
  };

  useCalendlyEventListener({
    onDateAndTimeSelected: () => {
      // if name does not include 'test'
      if (!state.name.toLowerCase().includes('test')) {
        // Allybot 2.0 - Trigger Booking Date and Time Select
        TagManager.dataLayer({
          dataLayer: {
            event: 'allybot2_date_time_selected',
          },
        });
      }
    },
    onEventScheduled: async () => {
      if (!state.name.toLowerCase().includes('test')) {
        // Allybot 2.0 - Trigger Booking Schedule Event
        TagManager.dataLayer({
          dataLayer: {
            event: 'allybot2_booking_scheduled',
          },
        });

        // Send RT_Appointment event to Red Track (booking successful)
        await sendAppointmentToRedTrack(state.params?.clickId);
      }

      // remove token from Local Storage at successful booking
      removeSessionToken();
    },
  });

  // Because we should preload two iframes at the time we cannot rely on onEventTypeViewed event
  // it will fire for every iframe loaded. So will rely on calendlyLink change that happens only once
  useEffect(() => {
    // Allybot 2.0 - Trigger Booking Page View
    if (state.calendlyLink && !state.name.toLowerCase().includes('test')) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'allybot2_booking_page_viewed',
        },
      });
    }
  }, [state.calendlyLink, state.name]);

  return (
    <div className="calendlyWrapper" style={state.step !== steps.CALENDLY_PAGE ? notVisibleStyles : {}}>
      <h1 className="calendlyHeading">Select A Convenient Time Here!</h1>
      <Spinner animation="border" variant="secondary" className="calendlySpinner" />

      <InlineWidget
        url={`https://calendly.com/vida-consutation/${phoneLink}`}
        pageSettings={{
          backgroundColor: 'ffffff',
          hideEventTypeDetails: true,
          hideGdprBanner: true,
          hideLandingPageDetails: false,
          primaryColor: '0060e6',
          textColor: '4d5055',
        }}
        prefill={{
          name: state.name,
          email: state.email,
        }}
        /* UTM parameters used to detect OP contact duplication in situation when 
            user enters different emails in Allybot and Calendly widget*/
        utm={{
          utmCampaign: 'Allybot 2.0', // used to distinguish between v1.0 and v2.0 bookings
          utmMedium: state.email,
        }}
        styles={state.calendlyLink === phoneLink ? widgetStyles : notVisibleStyles}
      />

      <InlineWidget
        url={`https://calendly.com/vida-consutation/${videoLink}`}
        pageSettings={{
          backgroundColor: 'ffffff',
          hideEventTypeDetails: true,
          hideGdprBanner: true,
          hideLandingPageDetails: false,
          primaryColor: '0060e6',
          textColor: '4d5055',
        }}
        prefill={{
          name: state.name,
          email: state.email,
        }}
        /* UTM parameters used to detect OP contact duplication in situation when 
            user enters different emails in Allybot and Calendly widget*/
        utm={{
          utmCampaign: 'Allybot 2.0', // used to distinguish between v1.0 and v2.0 bookings
          utmMedium: state.email,
        }}
        styles={state.calendlyLink === videoLink ? widgetStyles : notVisibleStyles}
      />
    </div>
  );
}
