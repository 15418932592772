import { useState, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { isEmailValid } from '../../Utils/functions';
import { useStepsContext } from '../../Hooks/useStepsContext';
import AlertComponent from '../Alert/AlertComponent';
import Input from '../Input/Input';
import Button from '../Button/Button';
import BotResponse from '../BotResponse/BotResponse';
import RadioOption from '../RadioOption/RadioOption';
import classes from 'classnames';

export default function ReadyToSpeak() {
  const {
    state,
    setState,
    next,
    back,
    setShouldSave,
    error,
    setError,
    animate,
    makeFakeLoading,
    fakeLoadingDuration,
    stepPassed,
  } = useStepsContext();
  const { readyToSpeak, email, name } = state;

  // we need to set email in context only once to not change iframe urls on every keystroke (prefill and utmMedium)
  const [emailValue, setEmailValue] = useState('');

  const handleReadyChange = (e) => {
    setShouldSave(true);
    setState((state) => ({ ...state, readyToSpeak: e.target.value }));
  };

  const handleEmailChange = (email) => {
    setShouldSave(true);
    setEmailValue(email);
  };

  let response;
  if (readyToSpeak === 'yes') {
    response = "Awesome, what's your best email address?";
  } else if (readyToSpeak === 'no') {
    response =
      "That's fine. Would you like to receive our best and most current dating tips by subscribing to our newsletter?";
  }

  const handleNext = (e) => {
    e.preventDefault();
    makeFakeLoading();

    setTimeout(() => {
      if (!readyToSpeak) return setError('not ready');
      if (!emailValue) return setError('empty');
      if (!isEmailValid(emailValue)) return setError('invalid');

      // set email only once
      setState((state) => ({
        ...state,
        email: emailValue,
        newsletterSignup: state.readyToSpeak === 'no' ? 'yes' : '',
      }));

      // trigger event only once - do not repeat it if user pressed 'Back'
      if (!stepPassed && !name.toLowerCase().includes('test')) {
        // Allybot 2.0 - Trigger Email Page Complete
        TagManager.dataLayer({
          dataLayer: {
            event: 'allybot2_email_page',
          },
        });
      }
    }, fakeLoadingDuration);
  };

  // TODO: workaround
  // email set in state only once and cannot be changed (no 'Back' button on the next page)
  // therefore this useEffect will run when email changes to not empty string
  useEffect(() => {
    if (email) next();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <form name="readyToSpeak" onSubmit={handleNext}>
      {error && <AlertComponent text="There was a problem with your submission. Errors are marked below." />}

      <BotResponse className={classes(animate(0), 'mb-20px')}>
        Great news... You've passed the initial qualification step & now it's time for us to speak! Let's get that set
        up now?
      </BotResponse>

      <div className={classes('btn-group-toggle', animate(1))} data-toggle="buttons">
        <RadioOption
          id="yes"
          name="readyToSpeak"
          value="yes"
          label="Sure, let's do this!"
          checked={readyToSpeak === 'yes'}
          onChange={handleReadyChange}
          hasError={error === 'not ready'}
          className="fz-14px"
        />
        <RadioOption
          id="no"
          name="readyToSpeak"
          value="no"
          label="I'm not ready yet"
          checked={readyToSpeak === 'no'}
          onChange={handleReadyChange}
          hasError={error === 'not ready'}
          className="fz-14px"
        />
      </div>

      {error === 'not ready' && <p className="has-error">You must select one of the options above.</p>}

      {readyToSpeak && (
        <>
          <BotResponse className={classes('btn-group-toggle', error && 'has-error', 'mb-20px')}>{response}</BotResponse>

          <Input
            type="email"
            value={emailValue}
            setValue={handleEmailChange}
            placeholder={readyToSpeak === 'no' ? 'Enter your BEST email address!' : ''}
            isInvalid={error === 'empty' || error === 'invalid'}
          />
        </>
      )}

      {error === 'empty' && <p className="has-error">This field cannot be blank.</p>}
      {error === 'invalid' && <p className="has-error">Email is invalid.</p>}

      <div className="frm_submit">
        <Button label="Back" onClick={back} />
        <Button label="Next" onClick={handleNext} />
      </div>
    </form>
  );
}
