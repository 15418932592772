import { useStepsContext } from '../../Hooks/useStepsContext';
import AlertComponent from '../Alert/AlertComponent';
import BotResponse from '../BotResponse/BotResponse';
import Button from '../Button/Button';
import RadioOption from '../RadioOption/RadioOption';

export default function NewsletterSignup() {
  const { state, setState, next, back, setShouldSave, error, setError, makeFakeLoading, fakeLoadingDuration } =
    useStepsContext();
  const { lookingFor, newsletterSignup } = state;

  const handleNewsletterSignupChange = (e) => {
    setShouldSave(true);
    setState((state) => ({ ...state, newsletterSignup: e.target.value }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    makeFakeLoading();

    setTimeout(() => {
      if (!newsletterSignup) return setError('error');

      next();
    }, fakeLoadingDuration);
  };

  const response =
    lookingFor === 'I am looking to meet single women'
      ? 'My team of experts just created a cool bundle of Bumble, Tinder and Hinge and you can get it now for free. Would you like me to send it to you now?'
      : "Would you like to receive the best dating advice from VIDA Select's top experts so you can catch and keep your ideal match?";

  return (
    <form name="newsletterSignup" onSubmit={handleNext}>
      {error && <AlertComponent text="There was a problem with your submission. Errors are marked below." />}

      <BotResponse className="mb-20px">{response}</BotResponse>

      <div className="btn-group-toggle" data-toggle="buttons">
        <RadioOption
          id="yes"
          name="newsletterSignup"
          value="yes"
          label="Yes"
          checked={newsletterSignup === 'yes'}
          onChange={handleNewsletterSignupChange}
          hasError={!!error}
        />
        <RadioOption
          id="no"
          name="newsletterSignup"
          value="no"
          label="No"
          checked={newsletterSignup === 'no'}
          onChange={handleNewsletterSignupChange}
          hasError={!!error}
        />
      </div>

      {error && <p className="has-error">You must select one of the options above.</p>}

      <div className="frm_submit">
        <Button label="Back" onClick={back} />
        <Button label="Next" onClick={handleNext} />
      </div>
    </form>
  );
}
