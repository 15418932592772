import TagManager from 'react-gtm-module';
import { useStepsContext } from '../../Hooks/useStepsContext';
import { isPrimeCity } from '../../Utils/functions';
import AlertComponent from '../Alert/AlertComponent';
import Button from '../Button/Button';
import BotResponse from '../BotResponse/BotResponse';
import GoogleAutoSuggest from '../GoogleAutoSuggest/GoogleAutoSuggest';
import classes from 'classnames';

export default function EnterCity() {
  const {
    state,
    setState,
    next,
    back,
    setShouldSave,
    error,
    setError,
    animate,
    makeFakeLoading,
    fakeLoadingDuration,
    stepPassed,
  } = useStepsContext();

  const { address, name } = state;

  const handleSetAddress = (address) => {
    setShouldSave(true);
    setState((state) => ({ ...state, address }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    makeFakeLoading();

    setTimeout(() => {
      if (!address) return setError('error');

      // trigger event only once - do not repeat it if user pressed 'Back'
      if (!stepPassed && !name.toLowerCase().includes('test')) {
        // Allybot 2.0 - Trigger City Page Complete
        TagManager.dataLayer({
          dataLayer: {
            event: 'allybot2_city_page',
          },
        });
      }

      next();
    }, fakeLoadingDuration);
  };

  return (
    <form name="address" onSubmit={handleNext}>
      {error && <AlertComponent text="There was a problem with your submission. Errors are marked below." />}

      <BotResponse className={classes(animate(0), error && 'has-error', 'mb-20px')}>
        What city would you like to meet matches in?
      </BotResponse>

      <GoogleAutoSuggest address={address} setAddress={handleSetAddress} className="fade-in" isInvalid={!!error} />

      {address && isPrimeCity(address) && (
        <BotResponse className="mb-30px">
          Perfect, that's one of our top metro areas where we get the most clients!
        </BotResponse>
      )}

      {error && <p className="has-error">Please click the Next again.</p>}

      <div className="frm_submit">
        <Button label="Back" onClick={back} />
        <Button label="Next" onClick={handleNext} />
      </div>
    </form>
  );
}
