import { useEffect, useState } from 'react';
import { useStepsContext } from '../../Hooks/useStepsContext';
import classes from 'classnames';

import './style.css';

export default function Button({ backgroundColor, label, className, onClick, ...props }) {
  const { fakeLoading } = useStepsContext();
  const [active, setActive] = useState('');

  const handleButtonClick = (e) => {
    setActive(e.target.textContent);
    onClick(e);
  };

  useEffect(() => {
    if (!fakeLoading) setActive('');
  }, [fakeLoading]);

  return (
    <button
      {...props}
      type="button"
      className={classes('action-button', active === label && fakeLoading && 'spinner not-allowed', className)}
      disabled={fakeLoading}
      style={backgroundColor && { backgroundColor }}
      onClick={handleButtonClick}
    >
      {fakeLoading && active === label ? '' : label}
    </button>
  );
}
