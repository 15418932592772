import style from './calendlyPageFooter.module.scss';

const PlayIcon = () => {
  return (
    <div className={style.svg}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
      </svg>
    </div>
  );
};

export default function CalendlyPageFooter() {
  return (
    <>
      <section className={style.wrapper}>
        <h2 className={style.h2}>
          We’ll Be There Every Step Of The Way <br /> Until You Meet Your Perfect Match.
        </h2>
        <h3 className={style.h3}>Here’s what VIDA will do for you:</h3>
        <ul className={style.ul}>
          <li>
            <PlayIcon />
            <p>
              Highlight all your most appealing qualities so <b>you’ll be irresistible to your ideal match</b>.
            </p>
          </li>
          <li>
            <PlayIcon />
            <p>
              Attract matches you’re guaranteed to like so you can{' '}
              <b>say goodbye to the hassle and frustration of the modern dating scene</b>.
            </p>
          </li>
          <li>
            <PlayIcon />
            <p>
              <b>Give you back at least 10 hours of your time each week</b> that you’d probably end up spending glued to
              a screen, scrambling to get a date. Now you can use this time doing the things you truly enjoy!
            </p>
          </li>
          <li>
            <PlayIcon />
            <p>
              <b>Get you higher quality dates than you’ve experienced on your own</b>. We’ll make it as easy as showing
              up to meet great matches who are excited to meet you too!
            </p>
          </li>
          <li>
            <PlayIcon />
            <p>
              Arm you with everything you need to<b> secure a second date with your favorite matches</b>. (Be sure to
              check out your complimentary
              <i>
                “First Date Formula” video training program - highly recommended even if you feel like you’re awesome on
                dates.)
              </i>
            </p>
          </li>
          <li>
            <PlayIcon />
            <p>
              <b>Introduce you to your dream girl</b> so you can finally delete your profile and{' '}
              <u>never open a dating app again</u>!
            </p>
          </li>
        </ul>

        <div className={style.cta}>
          <h3 className={style.h2}>
            Why Leave Finding Your Ideal <br /> Woman To Chance?
          </h3>
          <h3 className={style.h3}>After All, Chance Doesn’t Have Anywhere Near A 78% Success Rate!*</h3>
          <a className={style.link} href="#top">
            Schedule Now
          </a>
          <p>*78% of VIDA Select clients meet someone special within 3.5 months. Results may vary.</p>
        </div>
      </section>
    </>
  );
}
