import TagManager from 'react-gtm-module';
import { useStepsContext } from '../../Hooks/useStepsContext';
import AlertComponent from '../Alert/AlertComponent';
import BotResponse from '../BotResponse/BotResponse';
import Button from '../Button/Button';
import RadioOption from '../RadioOption/RadioOption';
import classes from 'classnames';

export default function PhoneOrVideo() {
  const {
    state,
    setState,
    next,
    setShouldSave,
    error,
    setError,
    animate,
    makeFakeLoading,
    fakeLoadingDuration,
    stepPassed,
  } = useStepsContext();

  const { preferToMeetBy, name } = state;

  const handleMeetByChange = (e) => {
    setShouldSave(true);
    setState((state) => ({ ...state, preferToMeetBy: e.target.value }));
  };

  const handleNext = (e) => {
    e.preventDefault();
    makeFakeLoading();

    setTimeout(() => {
      if (!preferToMeetBy) return setError('error');

      // trigger event only once - do not repeat it if user pressed 'Back'
      if (!stepPassed && !name.toLowerCase().includes('test')) {
        // Allybot 2.0 - Trigger Phone Or Video Page Complete
        TagManager.dataLayer({
          dataLayer: {
            event: 'allybot2_phone_or_video_page',
          },
        });
      }

      next();
    }, fakeLoadingDuration);
  };

  return (
    <form name="preferToMeetBy" onSubmit={handleNext}>
      {error && <AlertComponent text="There was a problem with your submission. Errors are marked below." />}

      <BotResponse className={classes(animate(0), 'mb-20px')}>
        Got it! To be clear... Our quick, confidential chat is 100% free with zero obligation or pressure to join.
      </BotResponse>

      <BotResponse className={classes(animate(1), 'mb-20px')}>
        If we decide that we’re a good mutual fit to work together after speaking, my team & I will be excited to set
        you up with your perfect match.
      </BotResponse>

      <div className={animate(2)}>
        <BotResponse className="mb-14px">Would you prefer we meet over the phone or via video?</BotResponse>

        <div className="btn-group-toggle" data-toggle="buttons">
          <RadioOption
            id="phone"
            name="preferToMeetBy"
            value="phone"
            label="Phone"
            checked={preferToMeetBy === 'phone'}
            onChange={handleMeetByChange}
            hasError={error}
          />
          <RadioOption
            id="video"
            name="preferToMeetBy"
            value="video"
            label="Video"
            checked={preferToMeetBy === 'video'}
            onChange={handleMeetByChange}
            hasError={error}
          />
        </div>
      </div>

      {error && <p className="has-error">You must select one of the options above.</p>}

      {preferToMeetBy && (
        <div className="frm_submit">
          <Button label="Next" onClick={handleNext} />
        </div>
      )}
    </form>
  );
}
