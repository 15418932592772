import Alert from 'react-bootstrap/Alert';
import classes from 'classnames';
import './style.scss';

export default function AlertComponent({ text, className }) {
  return (
    <Alert variant="danger" className={classes('custom', className)}>
      {text}
    </Alert>
  );
}
