import { createContext, useEffect, useRef, useState } from 'react';
import { getChatFromDB, saveChatToDB, saveCompletedForm, sendLeadToRedTrack } from '../Utils/api';
import { useUserTracking } from '../Hooks/useUserTracking';
import { getCalendlyLinkByIncome, redirect } from '../Utils/functions';
import steps from '../Utils/constants';

const { VITE_HOME_PAGE, VITE_THANK_YOU_PAGE } = import.meta.env;
const {
  AGE_RANGE_OF_LOOKING_FOR,
  AGREED_TO_BUNDLE_GET_EMAIL,
  CALENDLY_PAGE,
  CURRENT_INCOME_LEVEL,
  DATING_OBJECTIVE,
  LOW_INCOME_AGREEMENT,
  NEWSLETTER_SIGNUP,
  PHONE_OR_VIDEO,
  READY_TO_SPEAK_GET_EMAIL,
} = steps;

const initialState = {
  step: 0,
  visitedSteps: [],
  lookingFor: '',
  gender: '',
  name: '',
  relationStatus: '',
  address: '',
  age: '',
  fromAge: '',
  toAge: '',
  matchEarn: '',
  income: '',
  datingObjective: '',
  readyToSpeak: '',
  email: '',
  lowIncomeDisclaimerAgreement: '',
  newsletterSignup: '',
  preferToMeetBy: '',
  calendlyLink: '',
  adjustIncome: false,
  phoneLink: '',
  videoLink: '',
  params: null,
};

export const StepsContext = createContext(initialState);

/* Redirect occurs after Calendly booking page to v1.0 */
export const StepsContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const [shouldSave, setShouldSave] = useState(false);
  const [error, setError] = useState(null);
  const [stateLoading, setStateLoading] = useState(true);
  const [leadType, setLeadType] = useState(null); // 'Prime', 'Foreign' or 'Junk'
  const [fakeLoading, setFakeLoading] = useState(false); // used to mimic server response processing
  const [done, setDone] = useState(false); // used to indicate form completion

  const { ipAddress, GAId, fingerprintId } = useUserTracking();

  const {
    step,
    visitedSteps,
    lookingFor,
    gender,
    income,
    email,
    lowIncomeDisclaimerAgreement,
    newsletterSignup,
    preferToMeetBy,
    calendlyLink,
    adjustIncome,
    phoneLink,
    videoLink,
  } = state;

  // Get state data from db
  useEffect(() => {
    const getState = async () => {
      setStateLoading(true);

      const data = await getChatFromDB();
      if (!data) return setStateLoading(false);

      setState(data.state);
      setLeadType(data.leadType);
      setStateLoading(false);
    };

    getState();
  }, []);

  // store previous income in ref to be able to detect income change
  const prevIncome = useRef(null);

  // determine calendly links beforehand to preload iframes and cache the assets
  // we don't know does client prefer phone or video, so preload both options for income
  useEffect(() => {
    if (leadType !== 'Junk' && income && !phoneLink && !videoLink) {
      prevIncome.current = income;
      const { man_phone, man_video, woman_phone, woman_video } = getCalendlyLinkByIncome(income, adjustIncome);

      const newPhoneLink = gender === 'I am a man' ? man_phone : woman_phone;
      const newVideoLink = gender === 'I am a man' ? man_video : woman_video;

      setState((state) => ({ ...state, phoneLink: newPhoneLink, videoLink: newVideoLink }));
    }
  }, [leadType, income, gender, adjustIncome, phoneLink, videoLink]);

  // set links to null if user changes income
  useEffect(() => {
    if (!income) return;

    if (income !== prevIncome.current) {
      setState((state) => ({ ...state, phoneLink: null, videoLink: null }));
    }
  }, [income]);

  // ref value persists between rerenders
  // used to indicate that request to '/completed' endpoint has been fired
  // and should not be refetched after rerender caused by calendly link setState()
  const isRequestToOpFired = useRef(null);

  // Send completed form data
  useEffect(() => {
    const saveData = async () => {
      if (calendlyLink) {
        isRequestToOpFired.current = true;

        await saveChatToDB({ ...state, step: CALENDLY_PAGE }); // save data to db
        await saveCompletedForm(); // save lead to Ontraport

        // if name does not include 'test'
        if (!state.name.toLowerCase().includes('test')) {
          await sendLeadToRedTrack(state.params?.clickId); // send clickId to Red Track analytics
        }

        setState((state) => ({ ...state, step: CALENDLY_PAGE }));
        setFakeLoading(false);
      }
    };

    // if form is completed and request does not yet fired
    if (done && !isRequestToOpFired.current) saveData();
  }, [done, step, state, calendlyLink]);

  const genderManOrLookingForWomen = gender === 'I am a man' || lookingFor === 'I am looking to meet single women';
  const lowIncome = ['0-45', '45-60', '60-75'].includes(income);
  const agreedToDisclaimer = lowIncomeDisclaimerAgreement === 'yes';
  const agreedToNewsletter = newsletterSignup === 'yes';

  // used to indicate whether user already visited the step page
  const stepPassed = visitedSteps.includes(step);

  // get animation class with passed duration
  const animate = (duration) => `animate-${duration}`;

  // imitate server response by delaying steps movement
  const fakeLoadingDuration = 500;

  const makeFakeLoading = () => {
    setFakeLoading(true);

    setTimeout(() => {
      setFakeLoading(false);
    }, fakeLoadingDuration);
  };

  // set new state and jump to another step
  const saveAndGoToStep = async (step) => {
    setState((state) => ({ ...state, step }));
    setFakeLoading(false);
    if (shouldSave) await saveChatToDB({ ...state, step });
  };

  const saveAndRedirect = async (url) => {
    await saveChatToDB({ ...state });
    redirect(url);
  };

  const next = async () => {
    setFakeLoading(true);
    setError(null); // drop error state on step forward

    // set visited steps with filtered duplicates
    const newVisitedSteps = [...new Set([...visitedSteps, step])];
    setState((state) => ({ ...state, visitedSteps: newVisitedSteps }));

    // skip "MatchEarn" if looking for Women or gender is Man
    if (step === AGE_RANGE_OF_LOOKING_FOR && genderManOrLookingForWomen) {
      return saveAndGoToStep(CURRENT_INCOME_LEVEL);
    }

    // jump to "ReadyToSpeak" email branch
    if (step === DATING_OBJECTIVE && !lowIncome) {
      return saveAndGoToStep(READY_TO_SPEAK_GET_EMAIL);
    }

    // jump to "Subcribe to newsletter"
    if (step === LOW_INCOME_AGREEMENT && lowIncome && agreedToNewsletter && agreedToDisclaimer) {
      return saveAndGoToStep(AGREED_TO_BUNDLE_GET_EMAIL);
    }

    // jump to "Passed the initial qualification"
    if (step === LOW_INCOME_AGREEMENT && lowIncome && agreedToDisclaimer) {
      return saveAndGoToStep(READY_TO_SPEAK_GET_EMAIL);
    }

    // jump from "Subscribe to newsletter" to "Phone or Video"
    if (step === AGREED_TO_BUNDLE_GET_EMAIL && agreedToDisclaimer && email) {
      return saveAndGoToStep(PHONE_OR_VIDEO);
    }

    // Exit from chat on low income and email subscription refuse
    if (step === NEWSLETTER_SIGNUP && newsletterSignup === 'no') {
      return saveAndRedirect(VITE_HOME_PAGE);
    }

    // Exit from chat on low income and email subscription agree
    if (step === AGREED_TO_BUNDLE_GET_EMAIL && agreedToNewsletter && email) {
      await saveChatToDB({ ...state });
      if (leadType !== 'Junk') await saveCompletedForm();
      return redirect(VITE_THANK_YOU_PAGE);
    }

    // redirect from calendly page if lead type is junk
    if (step === PHONE_OR_VIDEO && leadType === 'Junk') {
      return saveAndRedirect(VITE_THANK_YOU_PAGE);
    }

    // save chat state
    if (shouldSave) {
      const newState = { ...state, step: step + 1, visitedSteps: newVisitedSteps };
      const chat = await saveChatToDB(newState, ipAddress, GAId, fingerprintId);

      if (chat.leadType) setLeadType(leadType); // set lead type if got one
      if (chat.adjustIncome) setState((state) => ({ ...state, adjustIncome })); // set adjustIncome
      setShouldSave(false); // do not save if no changes were made
    }

    // if user completed the form and it's leadType not 'Junk' - set calendly link and mark form as 'Done'
    if (step === PHONE_OR_VIDEO && preferToMeetBy && leadType !== 'Junk') {
      setState((state) => ({ ...state, calendlyLink: preferToMeetBy === 'phone' ? phoneLink : videoLink }));
      return setDone(true);
    }

    setFakeLoading(false);
    setState((state) => ({ ...state, step: state.step + 1, visitedSteps: newVisitedSteps })); // one step forward
  };

  const back = () => {
    setFakeLoading(false);
    makeFakeLoading();

    setTimeout(() => {
      setError(null); // drop error state on step back

      // skip "MatchEarn" if looking for Women or gender is Man
      if (step === CURRENT_INCOME_LEVEL && genderManOrLookingForWomen) {
        return setState((state) => ({ ...state, step: AGE_RANGE_OF_LOOKING_FOR }));
      }

      // back from "ReadyToSpeak" email branch to "DatingObjective"
      if (step === READY_TO_SPEAK_GET_EMAIL && !lowIncome) {
        return setState((state) => ({ ...state, step: DATING_OBJECTIVE }));
      }

      // back from "ReadyToSpeak" email branch to "LowIncomeDisclaimerAgreement"
      if (step === READY_TO_SPEAK_GET_EMAIL && lowIncome) {
        return setState((state) => ({ ...state, step: LOW_INCOME_AGREEMENT }));
      }

      // back from "ReadyToSpeak" email branch to "LowIncomeDisclaimerAgreement"
      if (step === READY_TO_SPEAK_GET_EMAIL && agreedToDisclaimer) {
        return setState((state) => ({ ...state, step: DATING_OBJECTIVE }));
      }

      // back from "Subscribe to newsletter" to "Disclaimer page"
      if (step === AGREED_TO_BUNDLE_GET_EMAIL && agreedToDisclaimer) {
        return setState((state) => ({ ...state, step: LOW_INCOME_AGREEMENT }));
      }

      setState((state) => ({ ...state, step: state.step - 1 })); // one step back
    }, fakeLoadingDuration);
  };

  return (
    <StepsContext.Provider
      value={{
        state,
        setState,
        next,
        back,
        setShouldSave,
        error,
        setError,
        stateLoading,
        fakeLoading,
        makeFakeLoading,
        fakeLoadingDuration,
        animate,
        leadType,
        phoneLink,
        videoLink,
        stepPassed,
      }}
    >
      {children}
    </StepsContext.Provider>
  );
};
