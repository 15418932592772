import './App.scss';
import Layout from './Components/Layout/Layout';
import Steps from './Components/Steps';
import { StepsContextProvider } from './Context/stepsContext';

export default function App() {
  return (
    <StepsContextProvider>
      <Layout>
        <Steps />
      </Layout>
    </StepsContextProvider>
  );
}
