import TagManager from 'react-gtm-module';
import { useStepsContext } from '../../Hooks/useStepsContext';
import AlertComponent from '../Alert/AlertComponent';
import Button from '../Button/Button';
import BotResponse from '../BotResponse/BotResponse';
import RadioOption from '../RadioOption/RadioOption';
import classes from 'classnames';

export default function LookingFor() {
  const {
    state,
    setState,
    next,
    setShouldSave,
    error,
    setError,
    animate,
    fakeLoadingDuration,
    makeFakeLoading,
    stepPassed,
  } = useStepsContext();

  const { lookingFor, gender } = state;

  const handleLookingForChange = (e) => {
    setShouldSave(true);
    setState({ ...state, lookingFor: e.target.value });
  };

  const handleGenderChange = (e) => {
    setShouldSave(true);
    setState({ ...state, gender: e.target.value });
  };

  const handleNext = (e) => {
    e.preventDefault();
    makeFakeLoading();

    setTimeout(() => {
      if (!lookingFor) return setError('lookingFor');
      if (!gender) return setError('gender');

      const genderString = gender === 'I am a man' ? 'male' : 'female';
      const lookingForString = lookingFor === 'I am looking to meet single women' ? 'women' : 'men';

      // set `vs_gender` cookie to determine gender in second part of the form
      document.cookie = `vs_gender=${genderString}; domain=.vidaselect.com; path=/`;

      // set `vs_looking_gender` cookie to determine looking for in second part of the form
      document.cookie = `vs_looking_gender=${lookingForString}; domain=.vidaselect.com; path=/`;

      // trigger event only once - do not repeat it if user pressed 'Back'
      if (!stepPassed) {
        // Allybot 2.0 - Trigger Form Start
        TagManager.dataLayer({
          dataLayer: {
            event: 'allybot2_started',
            // pass client's gender and lookingFor as data layer parameters
            allybot2_gender: genderString,
            allybot2_looking_for: lookingForString,
          },
        });
      }

      next();
    }, fakeLoadingDuration);
  };

  return (
    <form name="lookingFor" onSubmit={handleNext}>
      {error && <AlertComponent text="There was a problem with your submission. Errors are marked below." />}

      <BotResponse className={classes(animate(0), 'mb-14px')}>
        Hi, I'm Ally, your matchmaker here at VIDA Select! How can I help you?
      </BotResponse>

      <div className={`btn-group-toggle ${animate(1)}`} data-toggle="buttons">
        <RadioOption
          id="women"
          name="lookingFor"
          value="I am looking to meet single women"
          label="I am looking to meet single women"
          checked={lookingFor === 'I am looking to meet single women'}
          onChange={handleLookingForChange}
          hasError={error === 'lookingFor'}
        />
        <RadioOption
          id="men"
          name="lookingFor"
          value="I am looking to meet single men"
          label="I am looking to meet single men"
          checked={lookingFor === 'I am looking to meet single men'}
          onChange={handleLookingForChange}
          hasError={error === 'lookingFor'}
        />
        {error === 'lookingFor' && <p className="has-error">You must select one of the options above.</p>}
      </div>

      {state.lookingFor && (
        <>
          <BotResponse className={stepPassed ? '' : animate(0)}>Are you a man or a woman?</BotResponse>

          <div className={`btn-group-toggle ${stepPassed ? '' : animate(1)}`} data-toggle="buttons">
            <RadioOption
              id="man"
              name="gender"
              value="I am a man"
              label="I am a man"
              checked={gender === 'I am a man'}
              onChange={handleGenderChange}
              hasError={error === 'gender'}
            />
            <RadioOption
              id="woman"
              name="gender"
              value="I am a woman"
              label="I am a woman"
              checked={gender === 'I am a woman'}
              onChange={handleGenderChange}
              hasError={error === 'gender'}
            />
            {error === 'gender' && <p className="has-error">You must select one of the options above.</p>}
          </div>
        </>
      )}

      <div className="frm_submit">
        <Button label="Next" onClick={handleNext} />
      </div>
    </form>
  );
}
